import React, { useEffect, useState } from 'react';
import { useUser } from '@clerk/clerk-react';
import { useLocation } from 'react-router-dom';
import './app/globals.css';
import { NavBar } from './Navbar';
import { Footer } from './Footer';

const StripeSuccessPage: React.FC = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const sessionId = queryParams.get('session_id');
  const { user, isLoaded } = useUser();
  const [isPaymentVerified, setIsPaymentVerified] = useState(false);

  useEffect(() => {
    const verifyPayment = async () => {
      if (sessionId && isLoaded && user) {
        console.log(user.id);
        try {
          const response = await fetch('https://notion-timeline.onrender.com/api/checkout-success', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              sessionId,
              userId: user.id, // Send the user's ID to the backend
            }),
          });
          const data = await response.json();

          if (data.result) {
            console.log('Payment verified and user subscription updated');
            setIsPaymentVerified(true); // Update UI after successful verification
          } else {
            console.error('Payment verification failed:', data.error);
          }
        } catch (error) {
          console.error('Error verifying payment:', error);
        }
      }
    };

    verifyPayment();
  }, [sessionId, isLoaded, user]);

  return (
    <div className="flex flex-col min-h-screen bg-[#f9f9fd]">
      {/* Include NavBar */}
      <NavBar />
      <div className="p-4 flex flex-col items-center">
        <h1 className="text-2xl font-bold mb-4">Stripe Payment Status</h1>

        <div className="w-full max-w-md">
          <h2 className="text-xl font-semibold mb-4">Payment Information</h2>
          {isPaymentVerified ? (
            <div className="p-4 border border-green-500 bg-green-100 rounded">
              <h3 className="text-lg font-medium text-green-700">
                Payment Successful!
              </h3>
              <p className="text-green-600">Your subscription has been activated. Thank you for your purchase!</p>
            </div>
          ) : (
            <div className="p-4 border border-yellow-500 bg-yellow-100 rounded">
              <h3 className="text-lg font-medium text-yellow-700">
                Verifying your payment...
              </h3>
              <p className="text-yellow-600">Please wait while we verify your payment.</p>
            </div>
          )}
        </div>
      </div>
      {/* Include Footer */}
      <Footer />
    </div>
  );
};

export default StripeSuccessPage;
