import { Card, CardHeader, CardTitle, CardContent } from "./@/components/ui/card";
import step1Image from './assets/step1.png';
import step2Image from './assets/step2.png';
import step3Image from './assets/step3_edited.png';
// would a 3rd feature ever make sense here? ask chatty g
// 3 things, 1 would it make sense to put paper summary in the same app? 2. should we gen timelines if they aren't present based on current dates (prob not, weekly schedule is meh quality)? 3. should we reuse some of this code and/or migrate to next/astro for this or the next project 

// Probably want to just have a video tutorial of the flow instead, look at the guy who made shipfast for examples
export const Tutorial: React.FC = () => {
    return (
<section className="py-2 bg-[#f9f9fb]">  {/* Increased padding from py-16 to py-24 */}
  <div className="container mx-auto text-center">
    <h2 className="text-3xl font-bold mb-16">Tutorial</h2>  {/* Adjusted spacing for more breathing room */}
    <div className="grid grid-cols-1 md:grid-cols-3 gap-12">  {/* Increased gap between cards */}
      <Card className="shadow-lg hover:shadow-xl transition-shadow">  {/* Added shadow for more depth */}
        <CardHeader>
          <CardTitle>Step 1 - Login</CardTitle>
        </CardHeader>
        <CardContent>
        <img src={step1Image} alt="Clerk sign in page" />
        </CardContent>
      </Card>
      <Card className="shadow-lg hover:shadow-xl transition-shadow">
        <CardHeader>
          <CardTitle>Step 2 - Give Notion Access</CardTitle>
        </CardHeader>
        <CardContent>
        <img src={step2Image} alt="Notion auth page" />
        </CardContent>
      </Card>
      <Card className="shadow-lg hover:shadow-xl transition-shadow">
        <CardHeader>
          <CardTitle>Step 3 - Create Timeline</CardTitle>
        </CardHeader>
        <CardContent>
        <img src={step3Image} alt="Generate your timeline" />
        </CardContent>
      </Card>
    </div>
  </div>
</section>
    );
};
