import React, { useEffect } from "react";
import './app/globals.css';
import { NavBar } from "./Navbar";
import { Footer } from "./Footer";
import { Features } from "./Features";
import { Hero } from "./Hero";
import { useUser } from "@clerk/clerk-react";
import Pricing from "./Pricing";
import { Tutorial } from "./Tutorial";


// TODO - query the supabase db with the user_id from clerk, and retrieve notion id - then list pages or show the user their pages so they can choose
// where to make their timeline
// Also, might need to go disable RLS again or do something else so we can edit the rows again, need to think about security on this - can always just make a ts express endpoint
const LandingPage: React.FC = () => {
    const { user } = useUser();

  useEffect(() => {
    if (user) {
      console.log("User Information:", user);
    } else {
      console.log("No user is logged in.");
    }
  }, [user]);
  return (
    <div className="flex flex-col min-h-screen">
      <NavBar/>
      <Hero/>
      <Tutorial/>
      <Features/>
      <Pricing/>
      <Footer/>
    </div>
  );
};

export default LandingPage;
