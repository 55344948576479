import { CIcon } from '@coreui/icons-react';
import { cibNotion } from '@coreui/icons';
import CreateTimelineButton from './CreateTimelineButton';
import timelineExample from './assets/timelineExample.png';

export const Hero: React.FC = () => {
    return (

<section className="flex-grow bg-[#f9f9fd] py-8">  {/* Increased padding from py-20 to py-24 */}
  <div className="flex flex-wrap justify-center items-center space-x-2 md:space-x-4">
    <h1 className="text-dark text-[40px] md:text-[54px] leading-none font-semibold text-center pt-4 md:pt-0 md:max-w-full">
      The Best 
    </h1>
    <h1 className="text-dark text-[40px] md:text-[54px] leading-none font-semibold text-center pt-4 md:pt-0 md:max-w-full text-transparent bg-clip-text bg-gradient-to-r from-yellow-400 to-orange-600">
      Timeline Generator 
    </h1>
    <h1 className="text-dark text-[40px] md:text-[54px] leading-none font-semibold text-center pt-4 md:pt-0 md:max-w-full">
      for 
    </h1>
    <CIcon icon={cibNotion} size="xl" className="w-10 h-10 inline-block align-middle"/>
    <h1 className="text-dark text-[40px] md:text-[54px] leading-none font-semibold text-center pt-4 md:pt-0 md:max-w-full">
      Notion 
    </h1>
  </div>
  <div className="flex flex-wrap justify-center items-center">
    <h1 className="text-lg text-gray-500 my-6 md:mt-12 md:mb-4 text-center">
    <img src={timelineExample} alt="Clerk sign in page" />
    <div className="flex flex-wrap justify-center items-center pt-6"></div>
      Create beautiful timelines in Notion. 
    </h1>
  </div>

  <div className="flex flex-wrap justify-center items-center">
    <CreateTimelineButton buttonText='Create timeline for free'/>
  </div>
</section>
);
};