
import { useUser } from '@clerk/clerk-react';
import { useNotionAuth } from './hooks';
import { Link } from 'react-router-dom';
import { Button } from './@/components/ui/button';

interface CreateTimelineButtonProps {
    buttonText?: string;
}

const CreateTimelineButton: React.FC<CreateTimelineButtonProps> = ({ buttonText = "Create Timeline" }) => {
    const { user } = useUser()
    const notionAuth = useNotionAuth(user)
    return (
        <Link to={(!user || !notionAuth) ? "/login" : "/page-select"}>
        <Button variant="timeline">{buttonText}</Button>
        </Link>
    );
};

export default CreateTimelineButton;