import React from 'react';
import { Check } from 'lucide-react'; // Assuming you're using lucide-react for icons
import { Card } from './@/components/ui/card';
import { Button } from './@/components/ui/button';
import { Link } from 'react-router-dom';
import { useUser } from '@clerk/clerk-react';
import { useNotionAuth } from './hooks';

interface PricingCardProps {
    title: string;
    price: string;
    features: string[];
    isHighlighted: boolean;
  }

const PricingCard: React.FC<PricingCardProps> = ({ title, price, features, isHighlighted }) => {
    const { user } = useUser()
    const notionAuth = useNotionAuth(user)

    const handleCheckout = async () => { // we should export this to one function but I'm gonna delay fixing it for a minute for other priorities
        //const stripe = await stripePromise;
    
        if (!user) {
          console.error("User not logged in");
          return;
        }
    
        const response = await fetch('https://notion-timeline.onrender.com/api/create-checkout-session', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ userId: user.id }), // Pass the Clerk or Supabase user ID here
        });
    
        const { url } = await response.json();
        window.location.href = url; // Redirect to the Stripe checkout page
      };
    // ok there's some schenanigans around conditionally rendering the subscribe now button since onclick is seperate from navigation for stripe - handle in the morning
  return (
    <Card className={`p-6 ${isHighlighted ? 'bg-amber-500 text-white' : 'bg-white text-grey-900'} shadow-lg rounded-lg`}>
      <h3 className="text-2xl font-semibold">{title}</h3>
      <div className="mt-4">
        <span className="text-4xl font-bold">{price}</span>
        <span className="text-lg">/month</span>
      </div>
      <ul className="mt-6 space-y-4">
        {features.map((feature: string, index: number) => (
          <li key={index} className="flex items-center">
            <Check className="mr-2" />
            {feature}
          </li>
        ))}
      </ul>
      
      
      {!isHighlighted && <Link to={(!user || !notionAuth) ? "/login" : "/page-select"}>
      <Button
        className={`mt-6 w-full ${
            isHighlighted
            ? 'bg-white text-amber-500 hover:bg-gray-200 hover:text-amber-500'
            : 'bg-amber-500 text-white hover:bg-amber-600 hover:text-white'
        }`}
        >
        {isHighlighted ? 'Subscribe now' : 'Start now'}
        </Button>
        </Link>}

      {isHighlighted && (!user || !notionAuth) && <Link to="/login">
      <Button
        className={`mt-6 w-full ${
            isHighlighted
            ? 'bg-white text-amber-500 hover:bg-gray-200 hover:text-amber-500'
            : 'bg-amber-500 text-white hover:bg-amber-600 hover:text-white'
        }`}
        >
        {isHighlighted ? 'Subscribe now' : 'Start now'}
        </Button>
        </Link>}

        {isHighlighted && user && notionAuth && 
      <Button onClick={handleCheckout}
        className={`mt-6 w-full ${
            isHighlighted
            ? 'bg-white text-amber-500 hover:bg-gray-200 hover:text-amber-500'
            : 'bg-amber-500 text-white hover:bg-amber-600 hover:text-white'
        }`}
        >
        {isHighlighted ? 'Subscribe now' : 'Start now'}
        </Button>
        }
    </Card>
  );
};

const Pricing = () => { // strongly debating bumping to 10 timeline generations per month, but I still have received no complaints and am a little worried about 10 being too much - will keep thinking about this since you can never go back after making the free tier better imo
  return (
    <div className="flex flex-col justify-center items-center py-24 bg-[#f9f9fb]">
     <h2 className="text-3xl font-bold mb-16">Pricing</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
        <PricingCard // make sure this looks crisp and aligned well
          title="Basic"
          price="$0"
          features={[
            "10 timeline generations per month" 
          ]}
          isHighlighted={false}
        />
        <PricingCard
          title="Pro"
          price="$15"
          features={[
            "500 timeline generations per month"
          ]}
          isHighlighted={true}
        />
      </div>
    </div>
  );
};

export default Pricing;
