import { ReactComponent as MagicTimeIcon } from './assets/magichourglass.svg';
import { Link } from "react-router-dom";
import { Button } from "./@/components/ui/button";
import { useUser, UserButton } from '@clerk/clerk-react';
//import SubscribeButton from './SubscribeButton'; Ok this used to just always hang out next to the login button on the left - this looks a little weird,
// but TLDR I don't want users to think the product isn't free on the landing page, so subscribe is now only on the bottom of the page
import CreateTimelineButton from './CreateTimelineButton';
/*
{user && monthUsage != null && monthMaxUsage != null && <Button variant="loginStatic">Generations used this month: {monthUsage}/{monthMaxUsage}</Button>}
{(user && monthUsage == null || monthMaxUsage == null) && <Button variant="loginStatic">Generations used this month: ?/?</Button>}
*/

export const NavBar: React.FC = () => {
    const { user } = useUser()
return (
    <header className="py-4 border-b">
    <div className="container mx-auto flex justify-between items-center">
    <div className="flex items-center space-x-2">
        <MagicTimeIcon width="40" height="40" />
        <a href="/" className="text-xl leading-none font-semibold text-center pt-4 md:pt-0 md:max-w-full text-transparent bg-clip-text bg-gradient-to-r from-yellow-400 to-orange-600 inline-block relative">
        NoteTimelines
        <span className="absolute inset-x-0 bottom-0 h-[2px] bg-gradient-to-r from-yellow-400 to-orange-600 transform scale-x-0 origin-left transition-transform duration-300 hover:scale-x-100"></span>
        </a>
        </div>
        <div className="flex space-x-4">
        {user && <UserButton />}
        {user && <Link to="/profile-info">
            <Button variant="login">Profile Info</Button>  
        </Link>}
        {!user && <Link to="/login">
            <Button variant="login">Sign up or Login</Button>  
        </Link>}
        {user && <Link to="/login">
            <Button variant="login">Notion Authorization</Button>  
        </Link>}
        <CreateTimelineButton/>
        </div>
        </div>
    </header>
    )
}